<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add Email</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0">
          <v-text-field
            v-model.trim="$v.formData.name.$model"
            :error-messages="validateState('name') ? errorMessages : ''"
            label="Name"
            dense
            outlined
            clearable
          ></v-text-field>
          <v-text-field
            v-model.trim="$v.formData.email.$model"
            :error-messages="
              validateState('email')
                ? ' Email is required and a valid email address.'
                : ''
            "
            label="Email"
            dense
            outlined
            clearable
          ></v-text-field>
          <v-autocomplete
            v-model.trim="$v.formData.services.$model"
            :error-messages="validateState('services') ? errorMessages : ''"
            :items="services"
            dense
            outlined
            chips
            item-text="name"
            item-value="id"
            small-chips
            label="Services"
            multiple
          ></v-autocomplete>
          <v-card-text>
            <div class="d-flex justify-content-around">
              <v-switch
                v-model="formData.cc"
                color="primary"
                label="CC"
              ></v-switch>
              <v-switch
                v-model="formData.bcc"
                color="primary"
                label="Bcc"
              ></v-switch>
            </div>
          </v-card-text>
        </form>

        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
// import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
// import { getToken } from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      email: { required, email },
      name: { required },
      services: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    errorMessages: ["This Field is required"],
    formData: {
      name: null,
      email: null,
      cc: null,
      bcc: null,
      services: [],
    },
  }),
  computed: {
    services: function () {
      return this.$store.getters.getEMAILSTableServices;
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    filterData() {
      for (var key in this.formData) {
        if (key == "cc" || key == "bcc") {
          if (!this.formData[key]) this.formData[key] = false;
        } else if (!this.formData[key]) {
          return false;
        }
      }
      return true;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formData[name];
      return $dirty ? $error : null;
    },
    submitCreateForm() {
      this.$v.formData.$touch();
      if (this.$v.formData.$anyError) {
        return;
      }
      if (this.filterData() == false) {
        Swal.fire({
          title: "Error",
          text: "You should input all data",
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      this.pageLoader(true);
      const data = this.formData;
      ApiService.post("/settings/emails/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: "The Email setting has been created",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.resetCreateForm();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.formData = {
        name: null,
        email: null,
        cc: null,
        bcc: null,
        services: [],
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
};
</script>
